<template>
  <div class="body">
    <section class="cookieInfoSection" id="cookieInfoSection" style="display: none">
      <div class="cookieInfoBlock">
        <p>
          By continuing to use our site, you consent to our use of cookies. You can control and manage cookies through your browser settings. Please note that disabling cookies may affect your experience on our site. For more detailed information, please refer to our <router-link :to="{ name: 'Policy', hash: '#policySection' }">Privacy Policy</router-link>. Thank you for visiting Batting Tactics!
        </p>
        <button @click="acceptCookies('accepted')" class="mainButton">Accept</button>
        <button @click="declineCookies('declined')" class="mainButton">Decline</button>
      </div>
    </section>

    <section class="mainSection" id="mainSection">
      <div class="mainBlock">
        <div>
          <img src="../assets/bgImg.jpg" alt="BattingTactics" />
          <div>
            <h2>Batting Tactics</h2>
            <router-link :to="{ name: 'Main', hash: '#downloadSection' }" class="mainButton">DOWNLOAD</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="advantageSection">
      <div class="advantageBlock">
      </div>
    </section>      

    <section class="aboutSection" id="aboutSection">
      <div class="aboutBlock">
        <h1>Welcome to Batting Tactics: Elevate Your Cricket Game!</h1>

        <p>
          Are you ready to take your cricket skills to the next level? Introducing <b>Batting Tactics</b>, the ultimate cricket game app designed for players who are serious about perfecting their batting technique and strategy. Whether you're a seasoned cricketer or a newcomer to the game, Batting Tactics offers an engaging and interactive experience that combines real-world cricketing principles with cutting-edge technology.
        </p>

        <h2>Why Batting Tactics?</h2>

        <p>
          In today’s fast-paced game of cricket, having a strong batting strategy can make all the difference. Batting Tactics was created to help players understand the nuances of batting through immersive gameplay and detailed analytics. Our app focuses on developing your skills, enhancing your tactical awareness, and preparing you for various game situations. Whether you're looking for casual gameplay or serious competitive training, Batting Tactics has something for everyone.
        </p>

        <div class="aboutBlock__list">
          <div class="aboutBlock__img halfSizeBlock">
            <img src="../assets/app_1.jpg" alt="BattingTactics" />
          </div>
          <div class="aboutBlock__desc halfSizeBlock">
            <h3>Features of Batting Tactics</h3>

            <ul>
              <li><b>Interactive Gameplay:</b> Immerse yourself in realistic cricket scenarios that mimic actual matches. Develop your batting skills by playing through various challenges and levels designed to simulate real-world conditions and situations.</li>

              <li><b>Tactical Analysis:</b> Our app comes equipped with advanced analytics tools that provide insights into your performance. Understand your strengths and weaknesses, and receive tailored feedback on how to improve your gameplay.</li>

              <li><b>Customizable Batting Styles:</b> Everyone bats differently. Batting Tactics allows you to experiment with various batting techniques and styles to find what works best for you. Tailor your approach depending on the match scenario, pitch conditions, and the bowler’s style.</li>

              <li><b>Multiplayer Mode:</b> Challenge your friends or players from around the world in head-to-head matches. Test your skills and strategies against real opponents, fostering a competitive spirit and camaraderie among cricket enthusiasts.</li>

              <li><b>Regular Updates:</b> Our app is constantly evolving. With frequent updates, you’ll have access to new challenges, player stats, and tactical scenarios, ensuring that you always stay on top of your game.</li>
            </ul>
          </div>
        </div>

        <div class="aboutBlock__list">
          <div class="aboutBlock__desc halfSizeBlock">
            <h3>User-Friendly Interface</h3>

            <p>
              One of the standout features of Batting Tactics is its intuitive user interface. Navigate seamlessly through the app, whether you're selecting a challenge, analyzing your performance, or customizing your batting style. We've made it easy for players of all ages and skill levels to jump in and start enjoying the game.
            </p>

            <h3>Learning Resources</h3>

            <p>
              For those looking to improve their understanding of cricket strategies, Batting Tactics includes a wealth of resources. Access tutorials, video guides, and articles that cover a range of topics, from basic batting techniques to advanced strategic insights. With Batting Tactics, you'll not only play the game but also learn it.
            </p>
          </div>
          <div class="aboutBlock__img halfSizeBlock">
            <img src="../assets/app_2.jpg" alt="BattingTactics" />
          </div>
        </div>
        <div class="aboutBlock__list">
          <div class="aboutBlock__img halfSizeBlock">
            <img src="../assets/app_3.jpg" alt="BattingTactics" />
          </div>
          <div class="aboutBlock__desc halfSizeBlock">
            <h3>Community Engagement</h3>

            <p>
              Join a vibrant community of cricket lovers! Share tips, strategies, and experiences with fellow players within the app. Participate in community challenges and contribute to forums to exchange knowledge and insights about the game. When you play Batting Tactics, you become part of a global cricket family.
            </p>

            <h3>Commitment to Responsible Gaming</h3>

            <p>
              At Batting Tactics, we are committed to ensuring a safe and enjoyable gaming experience for our community. Please be advised that our app is designed for adults aged 18 and above. We encourage responsible gameplay and ask our users to respect the community guidelines.
            </p>
          </div>
        </div>

        <div class="aboutBlock__list">
          <div class="aboutBlock__desc halfSizeBlock">
            <h3>Get Started Today!</h3>

            <p>
              Are you ready to elevate your batting skills and strategy? Download <b>Batting Tactics</b> today and step into a world where cricket meets cutting-edge gaming technology. Available on both iOS and Android platforms, your cricketing journey awaits! 
            </p>

            <p>
              Unleash your batting potential, challenge your friends, and become a master strategist on the field with Batting Tactics. It’s time to revolutionize the way you play cricket—are you up for the challenge?
            </p>
            <p>
              <router-link :to="{ name: 'Main', hash: '#downloadSection' }" class="mainButton">Get App link</router-link>
            </p>
          </div>
          <div class="aboutBlock__img halfSizeBlock">
            <img src="../assets/app_4.jpg" alt="BattingTactics" />
          </div>
        </div>

        <h3>Final Thoughts</h3>

        <p>
          In the dynamic world of cricket, mastery of batting tactics can set you apart from the rest. With Batting Tactics, you are not just playing a game; you are honing your skills, understanding the intricacies of cricket, and preparing yourself for success on the field. Join us today and experience cricket like never before!
        </p>

        <div class="aboutBlock__list aboutBlock__listImg">
          <div class="aboutBlock__img">
            <img src="../assets/app_5.jpg" alt="BattingTactics" />
          </div>
        </div>

      </div>
    </section>

    <section class="reviewSection" id="reviewSection">
      <div class="reviewBlock">
        <div class="reviewBlock__list">
          <div class="reviewBlock__listItem">
            <h4>CricketFan91</h4>
            <span><b>November 2, 2023</b></span>
            <p>
              "Batting Tactics is an absolute game-changer for cricket enthusiasts! The strategic depth it offers during batting scenarios is incredible. I love how each match feels different with various challenges. The graphics are stunning, and the controls are user-friendly. Perfect for both beginners and seasoned players. Highly recommended!"
            </p>
            <div>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
          </div>
          <div class="reviewBlock__listItem">
            <h4>BattingPro_77</h4>
            <span><b>November 1, 2023</b></span>
            <p>
              "I'm really impressed with Batting Tactics! The tutorial is very helpful for understanding the strategies. The gameplay is smooth, and I appreciate the range of different pitches and bowling styles to contend with. My only complaint is that there could be more character customization options. Other than that, it’s a fantastic app!"
            </p>
            <div>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
            </div>
          </div>
          <div class="reviewBlock__listItem">
            <h4>CasualCricketer</h4>
            <span><b>October 30, 2023</b></span>
            <p>
              "Batting Tactics has some great ideas, but I feel like it could use more content. The gameplay is fun, and I enjoy the tactical approach, but after a few matches, it starts to feel a bit repetitive. I hope they add more game modes and challenges in future updates. Still worth downloading for cricket fans!"
            </p>
            <div>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
            </div>
          </div>
          <div class="reviewBlock__listItem">
            <h4>StrategyKing</h4>
            <span><b>October 29, 2023</b></span>
            <p>
              "This app is perfect for anyone who loves cricket and strategic games! The level of detail in each batting scenario really tests your skills. I appreciate how you can analyze your performances after each match. It has become a staple on my phone! I can’t wait to see what updates are coming next!"
            </p>
            <div>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
          </div>
          <div class="reviewBlock__listItem">
            <h4>NotImpressed2023</h4>
            <span><b>October 28, 2023</b></span>
            <p>
              "I had high hopes for Batting Tactics, but I was a bit disappointed. While the concept is solid, the execution feels lacking. There are some bugs that need fixing, and the AI can be a bit predictable. I hope the developers listen to the feedback and make improvements in future updates. Potential is there, but it needs work!"
            </p>
            <div>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
              <i class="fa fa-star-o" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    <section class="installSection" id="installSection">
      <div class="installBlock">
        <div class="installBlock__list">
          <div class="installBlock__listItem">
            <img src="../assets/download_3.png" alt="about image" />
            <span>Enable Unknown Sources</span>
          </div>
          <div class="installBlock__listItem">
            <img src="../assets/download_1.png" alt="about image" />
            <span>Download the "BattingTactics.apk"</span>
          </div>
          <div class="installBlock__listItem">
            <img src="../assets/download_2.png" alt="about image" />
            <span>Install the "BattingTactics.apk"</span>
          </div>
        </div>
      </div>
    </section>


    <section class="aboutSection" id="downloadSection">
      <div class="aboutBlock">
        <h3>
          To obtain the download link for the cricket game app "Batting Tactics," please send a message to us via email or phone. We will provide you with the link as soon as possible. 
        </h3>

        <div class="aboutBlock__list">
          <a href="mailto:BattingTactics@gmail.com" class="aboutBlock__contact halfSizeBlock">
            <h2>BattingTactics@gmail.com</h2>
          </a>
          <a href="tel:+918005553535" class="aboutBlock__contact halfSizeBlock">
            <h2>+918005553535</h2>
          </a>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MainComponent',
  props: {
    msg: String
  },
  data() {
    return {
      cookiesChoice: ''
    }
  },
  mounted() {
    this.cookiesChoice = localStorage.cookiesChoice;
      const cookieNotification = document.getElementById("cookieInfoSection");
      if (this.cookiesChoice === "accepted") {
        cookieNotification.style.display = "none";
      } else if (this.cookiesChoice === "declined") {
        cookieNotification.style.display = "none";
      } else {
        cookieNotification.style.display = "block";
      }
  },
  methods: {
    acceptCookies(cookie) {
      const cookieNotification = document.getElementById("cookieInfoSection");
      localStorage.setItem("cookiesChoice", cookie);
      cookieNotification.style.display = "none";
    },

    declineCookies(cookie) {
      const cookieNotification = document.getElementById("cookieInfoSection");
      localStorage.setItem("cookiesChoice", cookie);
      cookieNotification.style.display = "none";
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mainBlock, .advantageBlock, .catalogBlock, .installBlock, .aboutBlock, .contactBlock, .faqBlock, .reviewBlock {
    max-width: 1280px;
    margin: 0 auto;
  }

  ul {
    list-style: decimal;
  }
  ul li {
    margin: 10px 0;
  }
  .mainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 700;
    padding: 10px 20px;
    color: #FFFFFF;
    background: #000000;
    transition: all .4s;
    cursor: pointer;
    border: none;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid #000000;
  }
  .mainButton:hover {
    background: transparent;
    color: #000000;
  }

  /* cookieInfoSection */
  .cookieInfoSection {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #9ab4c9;
    color: #000000;
    z-index: 40;
    padding: 10px;
    border-top: 2px solid #95a5b7;
  }
  .cookieInfoBlock {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .cookieInfoBlock a {
    color: #000000;
    text-decoration: underline;
  }
  .aboutBlock__img:not(.halfSizeBlock) {
    width: 70%;
  }
  .aboutBlock__desc:not(.halfSizeBlock) {
    width: 30%;
  }


  /* mainSection */
  .mainSection {
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }
  .mainBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    position: relative;
    background: #FFFFFF;
    padding: 20px;
    margin: 30px auto;
    border-radius: 20px;
    width: 100%;
  }
  .mainBlock > div {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  .mainBlock div img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .mainBlock div div {
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 300px;
  }
  .mainBlock h2 {
    border-bottom: 2px solid #FFFFFF;
  }
  .mainBlock .mainButton {
    background: #95a5b7;
    color: #FFFFFF;
    border: 2px solid #95a5b7;
  }
  .mainBlock .mainButton:hover {
    background: #272c3d;
    color: #FFFFFF;
  }


  /* advantageSection */
  .advantageSection {
    padding: 20px 10px;
  }
  .advantageBlock {
    border-bottom: 2px solid #95a5b7;
  }


  /* installSection */
  .installSection {
    padding: 40px 10px;
  }
  .installBlock__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .installBlock__listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: 100%;
    gap: 10px;
  }
  .installBlock__listItem img {
    width: 70%;
  }
  .installBlock__listItem span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .appImg {
    border-radius: 50px;
  }


  /* aboutSection */
  .aboutSection {
    padding: 40px 10px;
  }
  .aboutBlock__list {
    display: flex;
    align-items: center;
    gap: 20px;
  }
	.aboutBlock__desc i {
		color: #00d26a;
	}
  .aboutBlock__img {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 20px;
  }
  .aboutBlock__img img {
    width: 100%;
    border-radius: 20px;
  }
  .aboutBlock__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
  .aboutBlock__listImg {
    justify-content: center;
  }


  /* reviewsSection */
  .reviewSection {
    padding: 40px 10px;
  }
  .reviewBlock__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .reviewBlock__listItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
  }
  .reviewBlock__listItem h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }
  .reviewBlock__listItem i {
    color: #f6bb06;
  }


  
  @media (max-width: 1024px) {
    .reviewBlock__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 768px) {
    .cookieInfoBlock {
      flex-direction: column;
    }
    .aboutBlock__list {
      flex-direction: column;
    }
    .aboutBlock__list:nth-child(2n) {
      flex-direction: column-reverse;
    }
    .halfSizeBlock,
    .aboutBlock__img:not(.halfSizeBlock),
    .aboutBlock__desc:not(.halfSizeBlock) {
      width: 100%;
    }
    .catalogBlock__list,
    .reviewBlock__list,
    .installBlock__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 516px) {
    .mainSection {
      min-height: auto;
    }
    .mainBlock {
      height: auto;
      padding: 5px;
    }
    .reviewBlock__list,
    .installBlock__list {
      grid-template-columns: repeat(1, 1fr);
    }
    .aboutBlock__img {
      padding: 7px;
    }
    .mainBlock div div {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
</style>
