<template>
  <section class="policySection" id="policySection">
    <div class="policyBlock">
      <h1>
        Terms and Conditions for Stake fantasy
      </h1>      

      <p>
        Effective Date: 17/05/2024
      </p>

      <p>
        Welcome to the "Stake fantasy" mobile application, developed by Stake fantasy ("Company," "we," "our," or "us"). By accessing or using our app, you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please do not use the app.
      </p>

      <h3>1. Acceptance of Terms</h3>
      <p>
        By downloading, installing, or using the Stake fantasy app, you confirm that you accept these Terms and agree to abide by them.
      </p>

      <h3>2. Eligibility</h3>
      <p>
        You must be at least 18 years old to use this app. If you are under the age of 18, you must have the consent of a parent or guardian.
      </p>

      <h3>3. User Account</h3>
      <p>
        To access certain features of the app, you may be required to create an account. You agree to:
      </p>
      <ul>
        <li>Provide accurate, current, and complete information during registration.</li>
        <li>Maintain the security of your password and account.</li>
        <li>Notify us immediately if you suspect any unauthorized use of your account.</li>
        <li>Be responsible for all activities that occur under your account.</li>
      </ul>

      <h3>4. License Grant</h3>
      <p>
        Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to use the app for personal and non-commercial purposes.
      </p>

      <h3>5. User Conduct</h3>
      <p>
        You agree not to:
      </p>
      <ul>
        <li>Use the app for any illegal or unauthorized purpose.</li>
        <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
        <li>Transmit any viruses, malware, or harmful code.</li>
        <li>Engage in any form of automated data collection, including, but not limited to, scraping, data mining, or harvesting.</li>
      </ul>

      <h3>6. Intellectual Property Rights</h3>
      <p>
        All content, features, and functionality of the app, including but not limited to text, graphics, logos, and software, are the exclusive property of the Company or its licensors and are protected by copyright, trademark, and other intellectual property laws.
      </p>

      <h3>7. In-App Purchases</h3>
      <p>
        The app may offer in-app purchases. By making a purchase, you agree to pay the fees associated with that purchase. All transactions are final, and no refunds will be issued unless required by law.
      </p>

      <h3>8. Termination</h3>
      <p>
        We reserve the right to suspend or terminate your access to the app at our discretion, without notice, for any violation of these Terms.
      </p>

      <h3>9. Disclaimers</h3>
      <p>
        The app is provided on an "as-is" and "as-available" basis. We make no warranties or representations about the functionality, reliability, or availability of the app.
      </p>

      <h3>10. Limitation of Liability</h3>
      <p>
        To the fullest extent permitted by law, in no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the app.
      </p>

      <h3>11. Indemnification</h3>
      <p>
        You agree to indemnify and hold the Company harmless from any claims, losses, or damages, including reasonable attorneys' fees, arising out of your use of the app or your violation of these Terms.
      </p>

      <h3>12. Governing Law</h3>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or related to these Terms shall be resolved exclusively in the courts located in India.
      </p>

      <h3>13. Changes to Terms</h3>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the app after any such changes constitutes your acceptance of the new Terms.
      </p>

      <h3>14. Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:BattingTactics@gmail.com" class="mail">BattingTactics@gmail.com</a> or <a href="tel:+918005553535">+918005553535</a>.
      </p>

    </div>
  </section>
</template>

<script>
export default {
  name: 'TermsComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .policySection {
    padding: 40px 10px;
  }
  .policyBlock {
    max-width: 1280px;
    margin: 0 auto;
  }
  .policyBlock a {
    text-decoration: underline;
    font-weight: 700;
    color: #000000;
  }
  .policyBlock ul {
    list-style: disc;
  }
  .policyBlock li {
    margin: 10px 0;
  }
</style>
