<template>
  <footer>
    <div class="footerBlock">
      <p>Security and Game Integrity</p>
      <div>
        <div>
          <img src="../assets/icon_1.png" alt="icon" />
          <div>
            <span>SLL</span>
            <span>
              Secure
              <br>
              Connection
            </span>
          </div>
        </div>
        <div>
          <img src="../assets/icon_2.png" alt="icon" />
          <div>
            <span>100%</span>
            <span>
              Legel & Secure
              <br>
              Connection
            </span>
          </div>
        </div>
      </div>
      <h3>Payment Partners</h3>
      <div>
        <img src="../assets/icon_3.png" alt="icon" />
        <img src="../assets/icon_4.png" alt="icon" />
        <img src="../assets/icon_5.png" alt="icon" />
        <img src="../assets/icon_6.png" alt="icon" />
        <img src="../assets/icon_7.png" alt="icon" />
        <img src="../assets/icon_8.png" alt="icon" />
      </div>
      <img src="../assets/icon_9.png" alt="18+" />
      <div>
        <a href="https://www.gamcare.org.uk" class="gambleLink" target="_blank"><img src="../assets/link_1.png" alt="link" /></a>
        <a href="https://www.gambleaware.org" class="gambleLink" target="_blank"><img src="../assets/link_2.png" alt="link" /></a>
      </div>

      <nav>
        <router-link :to="{ name: 'Policy', hash: '#policySection' }">Privacy Policy</router-link>
        <span>|</span>
        <router-link :to="{ name: 'Terms', hash: '#policySection' }">Terms & Conditions</router-link>
        <span>|</span>
        <router-link :to="{ name: 'Disclaimer', hash: '#policySection' }">Disclaimer</router-link>
      </nav>

      <nav>
        <a href="mailto:BattingTactics@gmail.com" class="mail">BattingTactics@gmail.com</a>
        <span>|</span>
        <a href="tel:+918005553535">+918005553535</a>
      </nav>
    </div>
    <div class="footerCopy">
      <p>
        Please note that this app is intended for adults aged 18 and over. Our content may include themes, language, or visuals that are not suitable for younger audiences. By accessing this site, you confirm that you are at least 18 years old. Enjoy the game responsibly!
      </p>
      <p>Copyright @2024</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* footer */
  footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .footerBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0;
    max-width: 1280px;
    margin: 0 auto;
    border-top: 2px solid #95a5b7;
  }
  .footerBlock > img {
    width: 50px;
    height: 50px;
  }
  .footerBlock > div > img {
    width: 90px;
    height: 40.45px;
  }
  .footerBlock > div > div > img {
    width: 40px;
    height: 50px;
  }
  .footerBlock > div, .footerBlock > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 5px auto;
    flex-wrap: wrap;
  }
  .footerBlock > div > div > div {
    display: flex;
    flex-direction: column;
  }
  .footerBlock p {
    font-size: 14px;
    font-weight: 400;
  }
  .footerBlock > div > div > div > span:first-child {
    font-weight: 700;
    font-size: 1.8rem;
  }
  .footerBlock > div > div > div > span:last-child {
    font-size: 10px;
  }
  .footerBlock h3 {
    font-weight: 400;
    font-size: 18px;
  }
  .footerBlock nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 15px;
    font-size: 14px;
    flex-wrap: wrap;
  }
  .footerBlock nav a:hover {
    text-decoration: underline;
  }
  .footerCopy {
    width: 100%;
    padding: 20px 0;
    max-width: 1280px;
    margin: 0 auto;
  }
  .footerCopy p {
    font-size: 14px;
    margin: 0 10px;
    text-align: center;
  }
  .footerBlock > div > a > img {
    height: 30px;
  }
  .gambleLink {
    background: #FFFFFF;
    padding: 7px;
    border-radius: 5px;
    height: 40px;
    width: auto;
  }
  .mail {
    word-break: break-word;
  }

  @media (max-width: 516px) {
    .footerBlock nav span {
      display: none;
    }
    .footerBlock nav {
      gap: 10px;
    }
  }
</style>
