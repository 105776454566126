<template>
  <Header />
  <main>
    <RouterView />
  </main>
  <Footer />
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
  .mainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 700;
    padding: 10px 20px;
    color: #272c3d;
    background: transparent;
    transition: all .4s;
    cursor: pointer;
    border: none;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid #95a5b7;
  }
  .mainButton:hover {
    background: #95a5b7;
    color: #FFFFFF;
  }

  .halfSizeBlock {
    width: 50%;
  }
  .mainImg {
    width: 100%;
    height: 600px;
    object-fit: contain;
    margin-top: 20px;
    border-radius: 30px;
  }

  @media (max-width: 1024px) {
    .mainImg {
      height: auto;
    }
  }

  @media (max-width: 516px) {
    h1, h2, h3 {
      font-size: 18px;
    }
    p, span, label, li, .mainButton {
      font-size: 14px;
    }
  }
</style>
