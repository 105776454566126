<template>
  <header id="header">
    <div class="headerBlock">
      <div>
        <RouterLink to="/"><img src="../assets/logo.png" alt="logo" /></RouterLink>
      </div>
      <nav>
        <router-link :to="{ name: 'Main', hash: '#aboutSection' }">About</router-link>
        <router-link :to="{ name: 'Main', hash: '#reviewSection' }">Reviews</router-link>
        <router-link :to="{ name: 'Main', hash: '#installSection' }">Installation</router-link>
        <router-link :to="{ name: 'Main', hash: '#downloadSection' }" class="mainButton">Download</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  header {
    z-index: 2;
    position: relative;
    background: transparent;
  }
  .headerBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    max-width: 1280px;
    margin: 0 auto;
    gap: 10px;
    border-bottom: 2px solid #95a5b7;
  }
  .headerBlock img {
    height: 60px;
  }
  .headerBlock > div {
    display: flex;
  }
  .headerBlock > div:last-child {
    display: flex;
    justify-content: end;
  }
  .mainButton {
    margin: 0;
  }
  .headerBlock > div > a {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .headerBlock nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  .headerBlock nav a:not(.mainButton) {
    text-transform: uppercase;
    color: #272c3d;
  }
  .headerBlock nav a:not(.mainButton):hover {
    color: #95a5b7;
  }
  
  @media (max-width: 768px) {
    .headerBlock {
      flex-direction: column;
    }
  }
</style>
