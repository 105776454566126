<template>
  <section class="policySection" id="policySection">
    <div class="policyBlock">
      <h1>
        Privacy Policy for Stake fantasy
      </h1>

      <p>
        Effective Date: 07/07/2024
      </p>

      <p>
        At Stake fantasy, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our cricket game app.
      </p>

      <h3>1. Information We Collect</h3>

      <p>
        We may collect the following types of information:
      </p>
      <ul>
        <li>Personal Information: When you create an account or interact with our app, we may ask for details like your name, email address, and other contact information.</li>
        <li>Usage Data: We collect data about how you interact with our app, including access times, pages visited, and device information.</li>
        <li>Cookies: We may use cookies and similar tracking technologies to enhance your experience and analyze usage patterns.</li>
      </ul>

      <h3>2. How We Use Your Information</h3>

      <p>
        We use your information to:
      </p>
      <ul>
        <li>Improve our app's functionality and user experience.</li>
        <li>Personalize content and advertisements.</li>
        <li>Communicate with you regarding your account, updates, and promotions.</li>
        <li>Conduct research and analyze trends to enhance app performance.</li>
      </ul>

      <h3>3. Sharing Your Information</h3>

      <p>
        We do not sell or rent your personal information to third parties. We may share your data with trusted partners for:
      </p>
      <ul>
        <li>Analyzing app usage patterns.</li>
        <li>Providing customer support.</li>
        <li>Complying with legal obligations.</li>
      </ul>

      <h3>4. Data Security</h3>

      <p>
        We implement a range of security measures to protect your personal information from unauthorized access, destruction, or alteration. However, no method of transmission over the Internet or method of electronic storage is 100% secure. 
      </p>

      <h3>5. Your Rights</h3>

      <p>
        You have the right to:
      </p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt-out of promotional communications.</li>
        <li>Request restrictions on processing your data.</li>
      </ul>

      <h3>6. Children’s Privacy</h3>

      <p>
        Stake fantasy is not intended for children under 18. We do not knowingly collect personal information from children. If we become aware of any data collected from children, we will take steps to delete such information.
      </p>

      <h3>7. Changes to This Privacy Policy</h3>

      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the effective date.
      </p>

      <h3>8. Contact Us</h3>

      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:  
      </p>

      <p>
        <a href="mailto:BattingTactics@gmail.com" class="mail">BattingTactics@gmail.com</a>
      </p>
      
      <p>
        <a href="tel:+918005553535">+918005553535</a>
      </p>

      <p>
        By using the Stake fantasy app, you consent to our Privacy Policy and agree to its terms. Thank you for trusting us with your information!
      </p>
        
    </div>
  </section>
</template>

<script>
export default {
  name: 'PolicyComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .policySection {
    padding: 40px 10px;
  }
  .policyBlock {
    max-width: 1280px;
    margin: 0 auto;
  }
  .policyBlock a {
    text-decoration: underline;
    font-weight: 700;
    color: #000000;
  }
  .policyBlock ul {
    list-style: disc;
  }
  .policyBlock li {
    margin: 10px 0;
  }
</style>
