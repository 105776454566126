import { createMemoryHistory, createRouter } from 'vue-router'

import Main from './components/Main.vue'
import Policy from './components/Policy.vue'
import Terms from './components/Terms.vue'
import Disclaimer from './components/Disclaimer.vue'

const routes = [
  { path: '/', name: "Main", component: Main },
  { path: '/policy', name: "Policy", component: Policy },
  { path: '/terms', name: "Terms", component: Terms },
  { path: '/disclaimer', name: "Disclaimer", component: Disclaimer },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
          el: to.hash
      }
    }
  }
})

export default router