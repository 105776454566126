<template>
  <section class="policySection" id="policySection">
    <div class="policyBlock">
      <h1>
        Disclaimer for "Batting Tactics" App
      </h1>

      <p>
        The "Batting Tactics" app is a fictional representation and is intended for entertainment purposes only. While the app aims to provide an engaging and enjoyable experience for cricket fans and players, please be aware of the following:
      </p>

      <ul>
        <li>No Real-World Impact: The strategies, gameplay mechanics, and outcomes within the "Batting Tactics" app do not have any real-world implications or effects on actual cricket matches or player performance.</li>

        <li>User Responsibility: Users are responsible for their own decisions when using the app. The information and tactics presented are based on standard cricket strategies and should not replace professional coaching or training.</li>

        <li>Third-Party Content: We do not endorse or take responsibility for any third-party applications, advertisements, or websites linked to or from the "Batting Tactics" app.</li>

        <li>Intellectual Property: All content, trademarks, and other intellectual property associated with the "Batting Tactics" app are owned by their respective owners. The use of any content from the app without permission is prohibited.</li>

        <li>Limitation of Liability: The developers and publishers of the "Batting Tactics" app shall not be liable for any damages arising from the use or inability to use the app, including but not limited to loss of profits, data, or other intangible losses.</li>

        <li>Updates and Changes: We reserve the right to modify or discontinue the "Batting Tactics" app or any part of it at any time without prior notice.</li>
      </ul>

      <p>
        By downloading or using the "Batting Tactics" app, you acknowledge that you have read and understood this disclaimer.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DisclaimerComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .policySection {
    padding: 40px 10px;
  }
  .policyBlock {
    max-width: 1280px;
    margin: 0 auto;
  }
  .policyBlock a {
    text-decoration: underline;
    font-weight: 700;
    color: #000000;
  }
  .policyBlock ul {
    list-style: decimal;
  }
  .policyBlock li {
    margin: 10px 0;
  }
</style>
